<template>
  <div justify="center">
    <v-btn
      color="secondary"
      outlined
      elevation="0"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="300px"
    >
      <div>
        <v-card>
          <v-card-title>税金を選択</v-card-title>
          <v-divider></v-divider>
          <v-data-table
            v-model="selectedList"
            show-select
            single-select
            :headers="Headers"
            hide-default-footer
            hide-default-header
            :items="AllList"
            item-key="id"
            add-tax="getTax"
            class="elevation-0"
            style="padding:0px"
          >
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="me-3"
              @click="goBack"
            >
              <span style="color:#fff">OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="me-3"
              @click="dialog = false"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiDotsHorizontal,
} from '@mdi/js'

export default {
  data: () => ({
    dialog: false,
    icons: {
      mdiDotsHorizontal,
    },
    opened: 0,
    addTax: [],
    selectedList: [],
    Headers: [
      {
        text: 'Tax',
        value: 'tax',
        align: 'right',
        width: '99%',
        sortable: false,
        fixed: true,
      },
    ],
    AllList: [
      {
        id: '1',
        tax: 8,
      },
      {
        id: '2',
        tax: 10,
      },
    ],
  }),
  watch: {
    selectedList(after) {
      this.addTax = after
    },
  },
  methods: {
    openForm() {
      this.dialog = true
    },
    goBack() {
      this.dialog = false
      this.$emit('add_tax', this.addTax)
      this.showSelect = false
    },
  },
}
</script>

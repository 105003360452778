<template>
  <v-radio-group class="px-5" column>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">単品販売<span style="color: red">*</span></label>
      </v-col>
      <v-col cols="12" md="2" class="py-0 my-0">
        <v-autocomplete
          v-model="product.item_able_tosale"
          :items="proSubject"
          placeholder="対象/非対象"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">セット販売<span style="color: red">*</span></label>
      </v-col>
      <v-col cols="12" md="2" class="py-0 my-0">
        <v-autocomplete
          v-model="product.set_able_tosale"
          :items="proSubject"
          placeholder="対象/非対象"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">定期便販売<span style="color: red">*</span></label>
      </v-col>
      <v-col cols="12" md="2" class="py-0 my-0">
        <v-autocomplete
          v-model="product.subs_able_tosale"
          :items="proSubject"
          placeholder="対象/非対象"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">通常販売価格(税込み)</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="2" class="py-0 my-0">
        <v-autocomplete
          id="item_up_show"
          v-model="product.item_up_show"
          :items="editShow"
          placeholder="表示/非表示"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="5" class="py-0 my-0">
        <v-text-field
          id="item_up_value"
          v-model="product.item_up_value"
          outlined
          dense
          placeholder="2,160"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">販売価格(税抜き)</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="2" class="py-0 my-0">
        <v-autocomplete
          :items="editShow2"
          placeholder="非表示/消し値表示"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="5" class="py-0 my-0">
        <v-text-field
          id="item_tep"
          v-model="product.item_tep"
          outlined
          dense
          placeholder="1,400"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">割引率</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="2" class="py-0 my-0">
        <v-autocomplete
          v-model="product.item_dr_show"
          :items="editShow"
          placeholder="表示/非表示"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="5" class="py-0 my-0">
        <v-text-field
          v-model="product.item_dr_value"
          suffix="%"
          outlined
          dense
          placeholder="30"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">消費税</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-text-field
          v-model="product.item_ctr"
          :value="taxs"
          outlined
          readonly
          dense
          placeholder="消費税"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="py-0 my-0">
        <TaxChoose @add_tax="getTax"></TaxChoose>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">販売価格(税込み)</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-text-field v-model="product.item_tip" outlined dense placeholder="1,512" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">販売開始日</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-menu
          v-model="dateStartMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="product.item_ssd"
              placeholder="販売開始日"
              dense
              hide-details
              outlined
              v-bind="attrs"
              clearable
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="product.item_ssd" @input="dateStartMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">販売終了日</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-menu
          v-model="dateEndMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              placeholder="販売終了日"
              dense
              hide-details
              outlined
              v-bind="attrs"
              clearable
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="endDate" @input="dateEndMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">販売可否</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-autocomplete :items="sellOver" placeholder="販売可否" outlined clearable dense hide-details></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">販売終了</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-autocomplete
          v-model="product.item_eos"
          :items="sellOver"
          placeholder="販売終了"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">在庫数</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-text-field outlined dense placeholder="在庫数" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">最小在庫数</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-text-field outlined dense placeholder="最小在庫数" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">販売可能在庫数</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-text-field outlined dense placeholder="販売可能在庫数" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" class="my-2">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">メタタグキーワード</label><span style="color: red">*</span>
      </v-col>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-text-field outlined dense placeholder="北海道" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
    <div class="d-flex justify-end mt-6">
      <v-btn color="secondary" outlined min-width="25%" @click="changeProduct1">
        <span>戻る</span>
      </v-btn>
      <v-btn color="primary" class="ml-3" min-width="25%" depressed @click="changeProduct2">
        <span style="color: #fff">次へ</span>
      </v-btn>
    </div>
  </v-radio-group>
</template>
<script>
import TaxChoose from '@/components/TaxChoose.vue'

export default {
  components: {
    TaxChoose,
  },
  props: {
    el: {
      type: Number,
      default: 4,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    submitStatus: false,
    proSubject: ['対象', '非対象'],
    editShow: ['表示', '非表示'],
    editShow2: ['非表示', '消し値表示'],
    sellOver: ['yes', 'no'],
    taxs: '',
    dateStartMenu: '',
    item_ssd: '',
    dateEndMenu: '',
    endDate: '',
  }),
  methods: {
    changeProduct1() {
      this.$emit('change1', this.product)
      this.$emit('change2', 3)
    },
    changeProduct2() {
      this.$emit('change1', this.product)
      this.$emit('change2', 4)
    },

    getTax(addTax) {
      if (addTax.length !== 0) {
        this.product.item_ctr = addTax[0].tax
      } else {
        this.product.item_ctr = ''
      }
    },
  },
}
</script>

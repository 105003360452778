<template>
  <v-radio-group class="px-5" column>
    <div>
      <div>
        <v-row align="center" class="my-2">
          <v-col cols="12" md="2">
            <label style="font-size:16px;color:#636363">JAN</label><span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="7" class="py-0 my-0">
            <v-text-field
              v-model="product.item_code"
              outlined
              dense
              placeholder="000000001"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="py-0 my-0">
            <v-btn color="secondary" outlined elevation="0">
              自社コード作成
            </v-btn>
          </v-col>
        </v-row>
        <v-row align="center" class="my-2">
          <v-col cols="12" md="2">
            <span style="font-size:16px;color:#636363">商品名</span><span style="color:red">*</span>
          </v-col>
          <v-col cols="12" md="7" class="py-0 my-0">
            <v-text-field
              v-model="product.item_name"
              dense
              placeholder="商品名"
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="my-2">
          <v-col cols="12" md="2">
            <label style="font-size:16px;color:#636363">メーカー名<span style="color: red">*</span></label>
          </v-col>
          <v-col cols="12" md="7" class="py-0 my-0">
            <v-text-field
              v-model="product.maker_name"
              outlined
              dense
              placeholder="富良野【スープカレー】チキン"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="my-12">
        <v-row align="center">
          <v-col cols="12" md="2" class="py-0 align-center">
            <span style="font-size:16px;">カテゴリー1</span><span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="10" class="py-0 align-center pl-2">
            <v-btn-toggle id="btnGroup" v-model="product.lc_code" group dense>
              <v-btn
                v-for="item in btnCategory1"
                :key="item.lc_code"
                elevation="0"
                class="mx-1"
                :style="{
                  'background-color': item.c1Clicked ? '#9155FD !important' : 'white !important',
                  color: item.c1Clicked ? 'white' : 'black',
                  'border-radius': '10px',
                }"
                style="border-radius:10px"
                @click="btnC1Click(item)"
              >
                {{ item.lc_name }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="2" class="py-0 align-center">
            <span style="font-size:16px;">カテゴリー2</span><span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="10" class="pl-0 align-center pl-2">
            <v-btn-toggle id="btnGroup" v-model="product.mc_code" group dense>
              <v-btn
                v-for="item in btnCategory2"
                :key="item.mc_code"
                elevation="0"
                class="mx-1"
                :style="{
                  'background-color': item.c2Clicked ? '#9155FD !important' : 'white !important',
                  color: item.c2Clicked ? 'white' : 'black',
                  'border-radius': '10px',
                }"
                style="border-radius:10px"
                @click="btnC2Click(item)"
              >
                {{ item.mc_name }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="2" class="py-0 align-center">
            <span style="font-size:16px;">カテゴリー3</span><span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="10" class="py-0 align-center pl-2">
            <v-btn-toggle id="btnGroup" v-model="product.sc_code" group dense>
              <v-btn
                v-for="item in btnCategory3"
                :key="item.sc_code"
                elevation="0"
                class="mx-1"
                :style="{
                  'background-color': item.c3Clicked ? '#9155FD !important' : 'white !important',
                  color: item.c3Clicked ? 'white' : 'black',
                  'border-radius': '10px',
                }"
                style="border-radius:10px"
                @click="btnC3Click(item)"
              >
                {{ item.sc_name }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex justify-end mr-2 mb-3">
        <v-btn color="secondary" outlined min-width="25%">
          <span>戻る</span>
        </v-btn>
        <v-btn id="btnChange" color="primary" class="ml-3" min-width="25%" depressed @click="changeProduct">
          <span style="color: #fff">次へ</span>
        </v-btn>
      </div>
    </div>
  </v-radio-group>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    el: {
      type: Number,
      default: 1,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    btnCategory1: [],
    btnCategory2: [],
    btnCategory3: [],
    C1: [],
    C2: [],
    C3: [],
    c1id: '0',
    c2id: '0',
    c3id: '0',
  }),
  computed: {
    ...mapState('categoryStore', ['categoryTree']),
  },
  created() {
    this.loadCategoryTree().then(() => {
      this.btnCategory1 = this.categoryTree
      this.btnCategory2 = this.btnCategory1[0].C2
      this.btnCategory3 = this.btnCategory2[0].C3

      this.btnC1Click(this.btnCategory1[0])
    })
  },
  methods: {
    ...mapActions('categoryStore', ['loadCategoryTree']),

    changeProduct() {
      this.$emit('change1', this.product)
      this.$emit('change2', 2)
    },

    btnC1Click(item) {
      /* eslint no-param-reassign: "error" */
      item.c1Clicked = true
      for (let i = 0; i < this.btnCategory1.length; i += 1) {
        if (this.btnCategory1[i] !== item) {
          this.btnCategory1[i].c1Clicked = false
        }
      }
      this.c1id = this.btnCategory1.indexOf(item)
      this.btnCategory2 = this.btnCategory1[this.c1id].C2
      this.btnC2Click(this.btnCategory2[0])
    },
    btnC2Click(item) {
      if ('mc_code' in this.btnCategory2[0] === true) {
        item.c2Clicked = true
      }
      for (let index = 0; index < this.btnCategory2.length; index += 1) {
        if (this.btnCategory2[index] !== item) {
          this.btnCategory2[index].c2Clicked = false
        }
      }
      this.c2id = this.btnCategory2.indexOf(item)
      this.btnCategory3 = this.btnCategory2[this.c2id].C3
      this.btnC3Click(this.btnCategory3[0])
    },
    btnC3Click(item) {
      item.c3Clicked = true
      for (let index = 0; index < this.btnCategory3.length; index += 1) {
        if (this.btnCategory3[index] !== item) {
          this.btnCategory3[index].c3Clicked = false
        }
      }
    },
  },
}
</script>

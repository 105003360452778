<template>
  <div>
    <v-stepper v-model="el">
      <v-stepper-header>
        <v-stepper-step :complete="el > 1" step="1">
          <span>商品-基本情報</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 2" step="2">
          <span>商品-属性</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 3" step="3">
          <span>商品-詳細</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          <span>商品-販売</span>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card outlined color="rgb(0,0,0,0)" class="px-2">
            <v-card-title>商品-基本情報</v-card-title>
            <Step1 :product="product" :el="el" @change1="changeProduct" @change2="changeEl">
              <Step1 />
            </Step1>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card outlined class="mb-6" color="rgb(0,0,0,0)">
            <v-card-title>商品-属性</v-card-title>
            <Step2 :product="product" :el="el" @change1="changeProduct" @change2="changeEl">
              <Step2 />
            </Step2>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card outlined class="mb-6" color="rgb(0,0,0,0)">
            <v-card-title>商品-詳細</v-card-title>
            <Step3 :product="product" :el="el" @change1="changeProduct" @change2="changeEl">
              <Step3 />
            </Step3>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card outlined class="mb-6" color="rgb(0,0,0,0)">
            <v-card-title>商品-販売</v-card-title>
            <Step4 :product="product" :el="el" @change1="changeProduct" @change2="changeEl">
              <Step4 />
            </Step4>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import Step3 from './Step3.vue'
import Step4 from './Step4.vue'

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },
  data: () => ({
    el: 1,
    product: {
      item_code: '',
      item_name: '',
      maker_name: '',
      lc_code: '',
      mc_code: '',
      sc_code: '',
      item_size_show: '',
      item_size_code: '',
      item_color_code: '',
      item_color_show: '',
      images: '',
      item_overview: '',
      item_desc: '',
      item_able_tosale: '',
      set_able_tosale: '',
      subs_able_tosale: '',
      item_up_show: '',
      item_up_value: '',
      item_tep: '',
      item_dr_show: '',
      item_dr_value: '',
      item_ctr: '',
      item_tip: '',
      item_ssd: '',
      item_eos: '',
      created_at: '',
      created_by: '',
      updated_at: '',
      updated_by: '',
    },
  }),
  methods: {
    changeProduct(val) {
      this.product = val
      console.log('this.product', this.product)
    },
    changeEl(val) {
      this.el = val
      console.log('this.el', this.el)
    },
  },
}
</script>

<template>
  <v-radio-group class="px-6" column>
    <v-row align="center" class="ma-0">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">サイズ</label>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="7">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="SizeForm.length"
              outlined
              dense
              placeholder="長さ"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="SizeForm.width" outlined dense placeholder="幅" hide-details="auto"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="SizeForm.height"
              outlined
              dense
              placeholder="高さ"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-end">
        <v-btn color="primary" elevation="0" @click="submitSizeForm(SizeForm)">
          <!-- <v-icon size="17" class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> -->
          <span>新規</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      ref="Size"
      v-model="selectedSize"
      show-select
      single-select
      item-key="no"
      fixed-header
      :headers="headers1"
      :items="SizeList"
      :items-per-page="-1"
      class="class-on-data-table elevation-1 mx-4 my-6"
    >
      <template v-slot:[`item.length`]="{ item }">
        <v-text-field
          v-if="item.readonly"
          v-model="item.length"
          placeholder="幅"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
        <v-text-field
          v-if="!item.readonly"
          v-model="SizeForm.length"
          placeholder="幅"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
      </template>
      <template v-slot:[`item.width`]="{ item }">
        <v-text-field
          v-if="item.readonly"
          v-model="item.width"
          placeholder="幅"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
        <v-text-field
          v-if="!item.readonly"
          v-model="SizeForm.width"
          placeholder="幅"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
      </template>
      <template v-slot:[`item.height`]="{ item }">
        <v-text-field
          v-if="item.readonly"
          v-model="item.height"
          placeholder="高さ"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
        <v-text-field
          v-if="!item.readonly"
          v-model="SizeForm.height"
          placeholder="高さ"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="item.readonly" class="mr-2" @click="doSelect(item)">
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon v-if="!item.readonly" class="mr-2" :loading="submitStatus" @click="doEdit(item)">
          {{ icons.mdiCheckBold }}
        </v-icon>
        <v-icon v-if="item.readonly" class="mr-2" @click="deleteSize(item)">
          {{ icons.mdiDelete }}
        </v-icon>
        <v-icon v-if="!item.readonly" class="mr-2" @click="cancelSelect(item)">
          {{ icons.mdiCloseThick }}
        </v-icon>
      </template>
    </v-data-table>
    <v-row align="center" class="ma-0">
      <v-spacer></v-spacer>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="product.item_size_show"
          :items="editShow"
          placeholder="表示/非表示"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="5">
        <v-text-field
          v-model="product.item_size_code"
          outlined
          dense
          clearable
          placeholder="長さ*幅*高さ"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-end">
        <v-btn color="primary" elevation="0" @click="getSize()">
          <!-- <v-icon size="17" class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> -->
          <span>選択</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-12"></v-divider>
    <v-row align="center" class="ma-0">
      <v-col cols="12" md="2">
        <label style="font-size:16px;color:#636363">カラー</label>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="ColorForm.rgb" outlined dense placeholder="RGB" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="ColorForm.name" outlined dense placeholder="カラー" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="secondary" outlined elevation="0" @click="dialog = true">
          <v-icon size="17" class="me-1">
            {{ icons.mdiDotsHorizontal }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-end">
        <v-btn color="primary" elevation="0" @click="submitColorForm(ColorForm)">
          <!-- <v-icon size="17" class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> -->
          <span>新規</span>
        </v-btn>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
          <v-card-title>カラー選択</v-card-title>
          <div class="d-flex justify-center mt-3 mb-8">
            <v-color-picker v-model="hex" elevation="1" width="300px"></v-color-picker>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mr-2" @click="dialog = false">
              <span>選択</span>
            </v-btn>
            <v-btn color="secondary" outlined @click="dialog = false">
              <span>戻る</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-data-table
      ref="Color"
      v-model="selectedColor"
      show-select
      single-select
      item-key="no"
      fixed-header
      :headers="headers2"
      :items="ColorList"
      :items-per-page="-1"
      class="class-on-data-table elevation-1 mx-4 my-6"
    >
      <template v-slot:[`item.image`]="{ item }">
        <div>
          <v-chip :color="item.image" style="width:100px"> </v-chip>
        </div>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-text-field
          v-if="item.readonly"
          v-model="item.name"
          placeholder="カラー"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
        <v-text-field
          v-if="!item.readonly"
          v-model="ColorForm.name"
          placeholder="カラー"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
      </template>
      <template v-slot:[`item.rgb`]="{ item }">
        <v-text-field
          v-if="item.readonly"
          v-model="item.rgb"
          placeholder="RGB"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
        <v-text-field
          v-if="!item.readonly"
          v-model="ColorForm.rgb"
          placeholder="RGB"
          hide-details="auto"
          readonly
          outlined
          dense
        >
        </v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="item.readonly" class="mr-2" @click="doSelect(item)">
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon v-if="!item.readonly" class="mr-2" :loading="submitStatus" @click="doEdit(item)">
          {{ icons.mdiCheckBold }}
        </v-icon>
        <v-icon v-if="item.readonly" class="mr-2" @click="deleteColor(item)">
          {{ icons.mdiDelete }}
        </v-icon>
        <v-icon v-if="!item.readonly" class="mr-2" @click="cancelSelect(item)">
          {{ icons.mdiCloseThick }}
        </v-icon>
      </template>
    </v-data-table>
    <v-row align="center" class="ma-0 my-2">
      <v-spacer></v-spacer>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="product.item_color_show"
          :items="editShow"
          placeholder="表示/非表示"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="5">
        <v-text-field
          v-model="product.item_color_code"
          placeholder="カラー"
          clearable
          outlined
          dense
          readonly
          hide-details="auto"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-end">
        <v-btn color="primary" elevation="0" @click="getColor()">
          <!-- <v-icon size="17" class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> -->
          <span>選択</span>
        </v-btn>
      </v-col>
    </v-row>
    <div class="d-flex justify-end mt-12">
      <v-btn color="secondary" outlined min-width="25%" @click="changeProduct1">
        <span>戻る</span>
      </v-btn>
      <v-btn color="primary" class="ml-3" min-width="25%" depressed @click="changeProduct2">
        <span style="color: #fff">次へ</span>
      </v-btn>
    </div>
  </v-radio-group>
</template>
<script>
/* eslint-disable */
import { mdiPlus, mdiDotsHorizontal, mdiDelete, mdiCloseThick, mdiPencil, mdiCheckBold } from '@mdi/js'
/* eslint-disable */

export default {
  props: {
    el: {
      type: Number,
      default: 2,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDotsHorizontal,
      mdiDelete,
      mdiCloseThick,
      mdiPencil,
      mdiCheckBold,
    },
    hex: '#FFFFFF',
    dialog: false,
    submitStatus: false,
    editShow: ['表示', '非表示'],
    selectedSize: [],
    selectedColor: [],
    ColorForm: {
      rgb: '',
      name: '',
      readonly: true,
    },
    SizeForm: {
      length: '',
      width: '',
      height: '',
      readonly: true,
    },
    headers1: [
      {
        text: 'No.',
        value: 'no',
        align: 'left',
        width: '8%',
        sortable: false,
        fixed: true,
      },
      {
        text: '長さ',
        value: 'length',
        width: '26%',
        align: 'center',
        sortable: false,
        fixed: true,
      },
      {
        text: '幅',
        value: 'width',
        width: '26%',
        align: 'center',
        sortable: false,
        fixed: false,
      },
      {
        text: '高さ',
        value: 'height',
        width: '26%',
        align: 'center',
        sortable: false,
        fixed: false,
      },
      {
        text: '　',
        value: 'actions',
        width: '14%',
        align: 'right',
        sortable: false,
        fixed: true,
      },
    ],
    SizeList: [
      {
        no: 1,
        length: 20,
        width: 30,
        height: 40,
      },
      {
        no: 2,
        length: 30,
        width: 40,
        height: 50,
      },
      {
        no: 3,
        length: 40,
        width: 50,
        height: 60,
      },
    ],
    headers2: [
      {
        text: 'No.',
        value: 'no',
        align: 'left',
        width: '8%',
        sortable: false,
        fixed: true,
      },
      {
        text: 'イメージ',
        value: 'image',
        width: '26%',
        align: 'center',
        sortable: false,
        fixed: false,
      },
      {
        text: 'RGB',
        value: 'rgb',
        width: '26%',
        align: 'center',
        sortable: false,
        fixed: false,
      },
      {
        text: 'カラー',
        value: 'name',
        width: '26%',
        align: 'center',
        sortable: false,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        width: '14%',
        align: 'right',
        sortable: false,
        fixed: true,
      },
    ],
    ColorList: [
      {
        no: 1,
        name: '紫',
        rgb: '#9155FD',
      },
      {
        no: 2,
        name: 'オレンジ',
        rgb: '#FA7A00',
      },
      {
        no: 3,
        name: '白',
        rgb: '#FFFFFF',
      },
    ],
  }),
  created() {
    this.refreshList()
  },
  watch: {
    hex(after) {
      this.ColorForm.rgb = after
    },
  },
  methods: {
    changeProduct1() {
      this.$emit('change1', this.product)
      this.$emit('change2', 1)
    },
    changeProduct2() {
      this.$emit('change1', this.product)
      this.$emit('change2', 3)
    },

    refreshList() {
      this.SizeList.forEach(item => {
        Object.assign(item, {
          readonly: true,
        })
      })
      console.log('SizeList', this.SizeList)
      this.ColorList.forEach(item => {
        Object.assign(item, {
          readonly: true,
          image: item.rgb,
        })
      })
      console.log('ColorList', this.ColorList)
    },

    doSelect() {},
    cancelSelect() {},
    doEdit() {},
    submitSizeForm(SizeForm) {
      if (this.SizeForm.length !== '' && this.SizeForm.width !== '' && this.SizeForm.height !== '') {
        this.SizeForm.no = this.SizeList.length + 1
        this.SizeList.push(SizeForm)
        this.SizeForm = {
          length: '',
          width: '',
          height: '',
          readonly: true,
        }
      }
    },
    submitColorForm(ColorForm) {
      if (this.ColorForm.rgb !== '' && this.ColorForm.name !== '') {
        this.ColorForm.no = this.ColorList.length + 1
        this.ColorForm.image = this.ColorForm.rgb
        this.ColorList.push(ColorForm)
        this.ColorForm = {
          rgb: '',
          name: '',
          readonly: true,
        }
      }
    },
    deleteSize(item) {
      this.SizeList.splice(this.SizeList.indexOf(item), 1)
      this.SizeList.forEach((v, i) => {
        this.SizeList[i].no = i + 1
      })
    },
    deleteColor(item) {
      this.ColorList.splice(this.ColorList.indexOf(item), 1)
      this.ColorList.forEach((v, i) => {
        this.ColorList[i].no = i + 1
      })
    },
    getSize() {
      console.log('selectedSize', this.selectedSize)
      this.product.item_size_code =
        this.selectedSize[0].length + '*' + this.selectedSize[0].width + '*' + this.selectedSize[0].height
      console.log('product.item_size_code', this.product.item_size_code)
    },
    getColor() {
      console.log('selectedColor', this.selectedColor)
      this.product.item_color_code = this.selectedColor[0].name
      console.log('product.item_color_code', this.product.item_color_code)
    },
  },
}
</script>

<template>
  <v-radio-group class="px-5" column>
    <div>
      <v-divider></v-divider>
      <div class="my-3" style="font-size:16px">
        イメージ
      </div>
      <v-divider></v-divider>
      <div class="d-flex justify-center align-center my-8">
        <v-img id="btnGroup" v-model="product.images" src="@/assets/images/noimage.jpg" max-width="120"> </v-img>
      </div>
      <div class="d-flex justify-center align-center my-4">
        <span>[ + ] ボタンを使用して、さらにイメージを追加します。</span>
      </div>
      <v-card-actions class="d-flex justify-end">
        <AddMedia></AddMedia>
      </v-card-actions>
      <v-divider></v-divider>
    </div>
    <div class="my-12">
      <div style="font-size:16px;color:black">
        商品概要
      </div>
      <div class="d-flex align-center flex-wrap">
        <v-btn color="primary" elevation="0" class="mb-4 me-3 mt-2" @click="onEditorStatus1">
          <span style="color:white">編集の表示/非表示</span>
        </v-btn>
        <v-btn color="primary" elevation="0" class="mb-4 me-3 mt-2" @click="showHide">
          <span style="color:white">Show / Hide</span>
        </v-btn>
      </div>
      <quill-editor
        v-if="showEditor1"
        id="item_overview"
        ref="myQuillEditor"
        v-model="item_overview"
        :options="editorOption1"
      />
      <v-textarea
        v-if="showNote1"
        v-model="item_overview"
        name="note1"
        hide-details="auto"
        color="#fa7a00"
        outlined
        :counter="100"
        dense
      >
      </v-textarea>
    </div>
    <div class="mb-10">
      <div style="font-size:16px;color:black">
        商品説明
      </div>
      <div class="d-flex align-center flex-wrap">
        <v-btn color="primary" elevation="0" class="mb-4 me-3 mt-2" @click="onEditorStatus2">
          <span style="color:white">編集の表示/非表示</span>
        </v-btn>
      </div>
      <quill-editor v-if="showEditor2" ref="myQuillEditor" v-model="item_desc" :options="editorOption2" />
      <v-textarea v-else v-model="item_desc" name="note2" hide-details="auto" outlined :counter="100" dense>
      </v-textarea>
    </div>
    <div>
      <div class="my-3" style="font-size:16px;color:black;">
        備考
      </div>
      <v-textarea
        name="note"
        hide-details="auto"
        outlined
        :counter="200"
        dense
        rows="5"
        placeholder="備考"
      ></v-textarea>
    </div>
    <div class="d-flex justify-end mt-6">
      <v-btn color="secondary" outlined min-width="25%" @click="changeProduct1">
        <span>戻る</span>
      </v-btn>
      <v-btn color="primary" class="ml-3" min-width="25%" depressed @click="changeProduct2">
        <span style="color: #fff">次へ</span>
      </v-btn>
    </div>
  </v-radio-group>
</template>
<script>
import AddMedia from '@/components/AddMedia.vue'

export default {
  components: {
    AddMedia,
  },
  props: {
    el: {
      type: Number,
      default: 3,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showEditor1: true,
    showNote1: false,
    note1: '<h1>Some initial note</h1>',
    item_overview: '<h1>お得な商品001</h1>',
    editorOption1: {},
    showEditor2: true,
    showNote2: false,
    note2: '<h1>Some initial note</h1>',
    item_desc: '<h1>お得な商品001</h1>',
    editorOption2: {},
  }),
  methods: {
    changeProduct1() {
      this.$emit('change1', this.product)
      this.$emit('change2', 2)
    },
    changeProduct2() {
      this.$emit('change1', this.product)
      this.$emit('change2', 4)
    },

    onEditorStatus1() {
      if (this.showEditor1 === false) {
        this.showEditor1 = true
        this.showNote1 = false
      } else if (this.showEditor1 === true) {
        this.showEditor1 = false
        this.showNote1 = true
      }
    },
    showHide() {
      if (this.showEditor1 === true || this.showNote1 === true) {
        this.showEditor1 = false
        this.showNote1 = false
      } else if (this.showEditor1 === false && this.showNote1 === false) {
        this.showEditor1 = true
        this.showNote1 = false
      }
    },
    onEditorStatus2() {
      this.showEditor2 = !this.showEditor2
    },
  },
}
</script>
